<div fxLayout="column" id="prod-details">
   
 
   <div class="color-b">
      <div class="container main p-10">
         <div class="mt-6">
            <div class="flex flex-col sm:flex-row justify-between items-center">
               <div class="text-lg font-semibold text-gray-800 mb-4 sm:mb-0 category-header">
                 <p class="text-secondary category-text">Home  >  <span class="cursor-pointer" (click)="gotocategory(product.Category)">{{product?.Category}}  >  </span> <span class="text-primary" >{{product?.ProductName}} </span></p>
               </div>
             
               <div class="relative">
               
               </div>
             </div>
         </div>
         <div class="details-wrapper p-10">
          
            <div fxLayout="column" fxFlex="100" *ngIf="product.ItemID">
               <div fxLayout="column" fxLayout.gt-sm="row" fxFlex="100" fxLayoutGap.gt-sm="40px">
                  <div fxFlex.gt-sm="50" fxLayout="column">

                     <!-- (click)="goToslider(product.ItemID)" -->
                     <div *ngIf="product.Images.length == 0" class="prodBox cursor-pointer" fxFlex="100" >
                        <img [src]="env.imageUrl +product.ImageUrl" alt="" class="img-bg img-fluid">
                     </div>

                     <div *ngIf="product.Images.length > 0" class="swiper-container m-b-20 gallery-top" [swiper]="GalleryTopImages">
                        <div class="swiper-wrapper">
                           <div class="swiper-slide cursor-pointer" *ngFor="let image of product.AllImagesUrls">
                              <img [src]="env.imageUrl + image.url" alt="" class="img-bg img-fluid rounded-2xl">
                           </div>
                        </div>
                     </div>

                     <!-- Image Slider ( Multiple Images ) -->

                     <div *ngIf="product.Images.length > 0" class="swiper-container gallery-thumbs" [swiper]="GalleryThumbImages">
                        <div class="swiper-wrapper related-images">
                           <div class="swiper-slide " *ngFor="let image of product.AllImagesUrls">
                              <img [src]="env.imageUrl + image.url" alt="" class="img-bg img-fluid product-images">
                           </div>
                        </div>
                     </div>

                  

                  </div>
                  <div fxFlex.gt-sm="50" fxFlex.gt-md="50" fxLayout="column"
                     fxLayoutAlign="start start" class="m-b-30 mt-[1.5rem]">
                     <div class="product-name">
                        <h2 class="Product-Title" id="Product-Title"><span class="Product"></span>{{product?.ProductName}}</h2>
                    </div>
                    <div class="product-price mt-4 mb-4">
                        <h2 class="text-3xl Product product-amount" >{{product?.OriginalPrice | currency:companyService.selectedCurrency.CurrencyCode}}</h2>
                    </div>
                    <div class="product-description ">
                        <h5 class="mb-2 text-start product-Text-description mt-4" style="font-size: medium;font-weight: 400;">
                        
                        </h5>
                     
                    </div>
                    <div class="product-categories mt-2 mb-4">
                        <ul>
                            <li><b>Categories:</b> {{product.Category}}</li>
                            <!-- <li><b>Ship To: </b> Las Vegas - United State</li> -->
                        </ul>
                    </div>
                    <div class="price-section w-full">
                     <div class="flex flex-col md:flex-row product-price-section ">
                        <section>
                            <mat-radio-group class="custom-radio-group" [(ngModel)]="itemPurchaseSelectionType">
                                <div class="flex-row normal-order-price" >

                                    <mat-radio-button class="example-margin text-base  custom-radio-button mb-2" value="type-one-time"
                                        checked>
                                        <span class=" text-start Autoship-Text text-base"> Just this once</span>
                                    </mat-radio-button>

                                    <div class="text-end">{{product?.OriginalPrice | currency:companyService.selectedCurrency.CurrencyCode}}</div>
                                </div>

                                <hr>
                                <mat-radio-button class="example-margin custom-radio-button mt-2" value="type-subscribe">
                                    <span class="Autoship-Text text-base">Autoship</span>
                                </mat-radio-button>
                            </mat-radio-group>
                        </section>
                        <div *ngIf="itemPurchaseSelectionType === 'type-subscribe'" class="mt-4">
                           <div class="delivery-frequency-container">
                              <mat-card class="box-shadow mb-3">
                                <mat-card-content>
                                  <mat-card-title class="mb-2">
                                   <small> DELIVERY FREQUENCY</small>
                                  </mat-card-title>
                                  
                                  <div class="autoship-listing">
                                    <div class="py-1">
                                      <div class="d-flex justify-between align-items-center">
                                        <mat-radio-group name="subscription" [(ngModel)]="persistentService.retailData.Autoship.FrequencyTypeID" class="custom-radio-group flex flex-col gap-[5px]">
                                          <mat-radio-button  *ngFor="let freq of commonData.FrequencyTypes" class="custom-radio example-margin custom-radio-button" [value]="freq.ID" (change)="setFrequency($event)"> {{ freq.Description | translate }}</mat-radio-button>
                                          <!-- <mat-radio-button  class="custom-radio example-margin custom-radio-button " value="monthly" id="chk_body_2" checked>Monthly</mat-radio-button> -->
                                        </mat-radio-group>
                                        
                                        <span>
                                          <span class="pro-price-display">{{product?.AutoshipPrice | currency:companyService.selectedCurrency.CurrencyCode}}</span> / delivery
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </mat-card-content>
                              </mat-card>
                            </div>
      
                            <div class="options-subscriptions">
                              <!-- Dropdown (Optional) -->
                              <mat-form-field *ngIf="false">
                                <mat-label>Choose Date</mat-label>
                                <mat-select name="sub_start" [(value)]="selectedDateOption">
                                  <mat-option value="2">Choose Date</mat-option>
                                </mat-select>
                              </mat-form-field>
                            
                              <!-- Start Date Picker -->
                              <div class="form-group sub-start-date">
                                 <mat-label class="text-xl"> Start Date</mat-label>
                                <mat-form-field appearance="fill" class="w-full">
                                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                  <input matInput [matDatepicker]="picker" placeholder="MM/DD/YYYY" [(ngModel)]="autoshipConfigurationService.autoshipDate" [matDatepickerFilter]="dateFilter" />
                                  <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                              </div>
                            </div>
                           </div>
                    </div>
                    </div>
                    
                    <div class="flex items-center mt-4">
                        <div class="flex border border-gray-300 rounded-lg overflow-hidden">
                          <button id="decrement" (click)="decreaseQuantity()" class="px-4 py-2 text-gray-600 hover:bg-gray-200">-</button>
                          <span id="count" class="px-4 py-2 text-gray-800">{{OrderQuantityCount}}</span>
                          <button id="increment" (click)="increaseQuantity()"  class="px-4 py-2 text-gray-600 hover:bg-gray-200">+</button>
                        </div>
                        <button class="bg-teal-500 text-white py-2 px-6 rounded-lg ml-4 hover:bg-teal-600" (click)="addToCart('check-type', product)">Add To Cart</button>
                      </div>
                  </div>
               </div>
               <!-- perfect stretch -->
               <!-- <div class="m-t-20">
                  <div fxLayout="column">
                     <div fxLayout="column" fxLayoutAlign="center" fxFlex="50" class="bg-img p-relative">
                        <img
                           src="https://cdn.shopify.com/s/files/1/0926/9298/files/AV200723_SH05_059.jpg?v=1600978896&width=1512"
                           alt="image">
                        <div class="content p-abs c-white">
                           <div class="p-relative p-content-div">
                              <p><span class="f-s-12">Our Gaurentee</span></p>
                              <p class="m-t-12 f-s-36 f-w-700">The Perfect Stretch</p>
                              <p class="m-t-20 w-35">Each {{'Clientname' | translate}} pieces comes tested in 360 degree
                                 flexibility tests. That's how we gaurentee the perfect stretch.</p>
                              <p class="m-t-30 shop-btn" routerLink="/products/all"><a class="c-black f-s-14">Shop
                                    now</a></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <hr style="margin:28px 0px; border-color:var(--theme-default-grey)"> -->
               <!--Recommended-->
               <!-- <div class="p-30">
                  <div fxLayout="column">
                     <p class="f-s-24 f-w-700">Recommended</p>
                     <div class="m-t-10" fxLayout="column" fxFill fxLayout.gt-xs="row"
                        fxLayoutAlign="flex-start flex-start" fxLayoutGap="20px" fxFlex="100">
                        <div *ngFor="let productImage of healthBeautyProduct; let i=index" fxLayout="column" fxFlex="32"
                           fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
                           <div fxFlex="70" fxFlex.lt-md="100" fxLayoutAlign="center center" (mouseover)="mouseEnter(i)"
                              (mouseout)="mouseOut(i)" fxLayout="column" fxFill class="p-relative cursor-pointer"
                              id="parent">
                              <img (click)="goToShop(productImage.ItemID)" [src]="env.imageUrl +productImage.ImageUrl"
                                 alt="" class="img-bg2" [id]="'product'+i" style="width: 100% !important;">
                              <div class="content" fxLayout="column" fxFill fxLayoutAlign="end">
                                 <div class="p-absolute abs-width">
                                    <button [id]="'btn'+i"
                                       class="shop-btn product-button d-none w-89 m-t-20 m-b-20 m-r-0 m-l-0"
                                       mat-raised-button color="black" (click)="addProduct(productImage)">Quick
                                       Add</button>
                                 </div>
                              </div>
                           </div>
                           <div fxFlex="10" fxLayout="row" class="content m-t-20">
                              <div fxFlex="70">
                                 <p><span class="font-IBM">{{productImage.ProductName}}</span></p>
                                 <p><span class="m-t-10 font-IBM"><del
                                          *ngIf="productImage.oldPrice">{{productImage.oldPrice}} </del>
                                       {{'$'+productImage.Price}}</span></p>
                              </div>
                              <div *ngIf="productImage.oldPrice" fxFlex="30" class="text-end m-t-10">
                                 <p class="f-s-12 sale" color="white">Sale</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div> -->
            </div>
         </div>
      </div>
      <div class="video-contant max-w-full">

         <div class="container justify-center text-center">
     
         <div class="contant mt-8">
             <h2 class="Autoship-Text text-3xl video-heading py-[3rem]">How {{getproductname(product.ProductName)}} <span class="">Works</span> </h2>
         </div>
         <div class="video">
     
            <div class="short-description text-center " style="font-size: medium;font-weight: 400;">
               
            </div>
             <!-- <div class="video-text mt-6">
                 <p class="text-start">Cell Defender (Cd) Is a colloidal Suspension of activated zeal light produced, marketed, and distributed by Pura supplies. Cd contains Clinoptilolite as the constituent Zeolite, A naturally occurring sodium Aluminosilicate. The difference between Cell Defender and all other zeolite colloidal suspension is the Rik Deitsch has developed an activation and micronizing proprietary process. This process results in zeolite particles sized as low as .39 microns which enables the zeolite to enter the bloodstream. In the white paper it will describe how this zeolite in the bloodstream removes heavy metals and toxins (proven in peer reviewed studies) which help improve the immune system so that the immune system is freed up. As Rik Deitsch says “ A healthy body can repair itself“. To Avini’s knowledge, no other company has ever been able to match the act of entering the bloodstream as efficiently as Cell Defender. Additionally, the zeolite suspension increases pH level to 7.4 which is important to the processes in the body. Significantly, viruses and cancer cells need an acidic environment to procreate, and the basic environment created by the zeolite will reduce their ability to reproduce. This product has had sales of millions of bottles in the last 17 years </p>
             </div> -->
     
         </div>
        <div class="research-link pt-6 pb-6">
         <div class="cursor-pointer" routerLinkActive="text-teal-600" routerLink="/scientific-research"><b>Scientific Research →</b></div>
         <!-- <a class="" routerLinkActive="text-teal-600" routerLink="/scientific-research"><b>Scientific Research →</b></a> -->
        </div>
         </div>
     </div>

      <!-- <div class="container main p-30">
         <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px">
            <div *ngFor="let relProduct of relatedProducts" class="" fxFlex.gt-sm="33" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
               <img [routerLink]="['/product', relProduct.ItemID]" [src]="configService.localSettings?.Disco?.DiscoUrl + '/' + relProduct.ImageUrl" alt="" class="w-20 cursor-pointer">
               <span class="c-black" [textContent]="relProduct?.ProductName"></span>
               <div class="price f-w-600 f-s-16 c-black"
                  [textContent]="getPrice(relProduct, OrderQuantityCount) | currency:companyService.selectedCurrency.CurrencyCode">
               </div>
               <div class="purchase-Quantity_Button_Box m-t-15" fxLayout="row" fxLayoutGap="18px" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="25px">
                  <button class="suggested-btn">
                     <div (click)="AddSuggestedProductToCart(relProduct)"> Add </div>
                  </button>
                  <button class="suggested-btn">
                     <div [routerLink]="['/product', relProduct.ItemID]"> Details </div>
                  </button>
               </div>
            </div>
         </div>


      </div> -->
      
      <div class="container mx-auto px-4 py-8 mt-8 related-products" style="padding: 10px !important;">
         <h2 class="text-center text-gray-800 text-3xl font-bold mb-8">
            <span style="color: #374B5C;">Related </span>   <span class="text-teal-500 product-text">Products</span>
         </h2>
         <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
     
           <!-- Product 1 -->
           <div class="text-center" *ngFor="let relProduct of relatedProducts">
            <div class="image-container">
              
             <img [src]=" 'https://avinihealth.corpadmin.directscale.com'+ relProduct.ImageUrl"  alt="" class="w-full h-auto mb-4 object-cover image cursor-pointer">

               <button mat-button class="center-button" (click)="navigateToProductDetails(relProduct.ItemID)"><mat-icon><b>add </b></mat-icon></button>
             </div>
             <h3 class="text-teal-500 text-xl text-center font-semibold product-text " [textContent]="relProduct?.ProductName"></h3>
             <h3 class="text-2xl product-name text-center product-price">{{getPrice(relProduct, OrderQuantityCount) | currency:companyService.selectedCurrency.CurrencyCode}}</h3>
           </div>
     
          
     
         </div>
       </div>

      </div>
      

</div>
<div class="container mx-auto px-4 py-8 mt-[4rem] mb-6">
   <h2 class="text-center text-gray-800 text-2xl sm:text-4xl font-bold mb-8">
    <span style="color: #374B5C;">What Do The </span> <span class="text-teal-500 product-text">People Say</span>
   </h2>
   <div class="testimonial-carousel">
       <swiper [config]="config">
         <div *ngFor="let testimonial of testimonials" class="swiper-slide border h-[70rem]">
           <div class="p-2 m-2 md:p-4 md:m-4 testimonial-content relative">
            <!-- Arrow Top Left -->
            <img src="../../../../../assets/images/testimonialquote.png" class="h-10 w-10 absolute top-0 left-0" alt="">
            <div class="review-img flex text-center justify-center">
                <img  [src]="testimonial.image" alt="User image" class="testimonial-image text-center">
            </div>
             <h3 class="text-center"><b>{{ testimonial.name }}</b></h3>
             <div class="stars">
               <i class="fa fa-star" *ngFor="let star of [1, 2, 3, 4, 5]"></i>
             </div>
            <img src="../../../../../assets/images/testimonialquote.png" class="h-10 w-10 absolute top-0 left-0" alt="">
            <p class="mt-4 mb-12">{{ testimonial.message }}</p>
            <!-- Arrow Bottom Right -->
            <img src="../../../../../assets/images/testimonialquote.png" class="h-10 w-10 absolute bottom-0 right-0 rotate-180" alt="">
           </div>
         </div>
         <!-- Pagination -->
         <!-- <div class="swiper-pagination"></div> -->
         <!-- Navigation Buttons -->
         <!-- <div class="swiper-button-prev"></div>
         <div class="swiper-button-next"></div> -->
       </swiper>
     </div>
 </div>
