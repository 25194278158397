import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { ProductService } from "src/app/components/shared/services/product.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { CartService } from "src/app/components/shared/services/cart.service";
import { SwiperDirective, SwiperConfigInterface } from "ngx-swiper-wrapper";
// import { ProductZoomComponent } from './product-zoom/product-zoom.component';
import { Cart1Service } from "src/app/components/shared/services/cart1.service";
import { ConfigService } from "src/app/components/shared/services/config.service";
import * as _ from "lodash";
import * as $ from "jquery";
import { ItemsListService } from "src/app/components/shared/services/itemsList.service";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { UtilityService } from "src/app/components/shared/services/utility.service";
import { PersistentService } from "src/app/components/shared/services/persistent.service";
import { AutoshipConfigurationService } from "src/app/components/shared/services/autoshipConfiguration.service";
import { CompanyService } from "src/app/components/shared/services/company.service";
import { environment } from "src/environments/environment";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "src/app/components/shared/model/confirm-dialog/confirm-dialog.component";
import { AccountService } from "src/app/components/shared/services/account.service";
import { UserService } from "src/app/components/shared/services/user.service";
import { NotificationService } from "src/app/components/shared/services/notification.service";
import { SideNavBarService } from "src/app/components/shared/services/sidenavbar.service";


@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public config: SwiperConfigInterface = {
    slidesPerView: 1.6,
    spaceBetween: 35,
    centeredSlides: true,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 20, // you can also adjust spaceBetween for mobile if needed
      }
    }
  };
  public GalleryThumbImages: SwiperConfigInterface = {};
  public GalleryTopImages: SwiperConfigInterface = {};

  @ViewChild("zoomViewer", { static: true }) zoomViewer;
  @ViewChild(SwiperDirective, { static: true }) directiveRef: SwiperDirective;

  public product: any = {};
  public products = [];
  public allItems: { Category?: string; CategoryId?: (string|number) }[] = [];
  public relatedProducts = [];
  public orderOptions;
  public image;
  public zoomImage;
  ezpModel = {};
  event;
  activeImage = "";
  value = [];
  defaultImage: any = {};
  isOptionsSelected: boolean;
  public counter: number = 1;
  public OrderQuantityCount;
  itemTypes = {};
  index: number;
  bigProductImageIndex = 0;
  CartType: string;
  itemType: string;
  type: string;
  ItemID;
  isShowShareOptions: boolean;
  panelOpenSpecification: boolean = false;
  panelOpenAutoship: boolean = false;
  healthBeautyProduct = [];
  env;
  selectedCategory;
  itemPurchaseSelectionType: string = '';
  QuantityArray: Array<number> = new Array(30);
  ProductSpecification = [];
  queryparams = {};
  testimonials = [
    {
      image: '../../../../../assets/images/image_2024_08_14T11_04_46_456Z.png',
      name: 'Thayne Shively',
      message: `I have had fibromyalgia & neuropathy for more than 25 years. I have been taking this 
      zeolite clinoptilolate product for 1 month. I have No fibro pain & I’m beginning to feel my feet. 
      Also brain fog is gone. This is a Life Changer for me & a true Miracle!!! I will never be without it! 
      THANK YOU...`
    },
    {
      image: '../../../../../assets/images/image_2024_08_14T11_04_46_456Z.png',
      name: 'Thayne Shively',
      message: `I have had fibromyalgia & neuropathy for more than 25 years. I have been taking this 
      zeolite clinoptilolate product for 1 month. I have No fibro pain & I’m beginning to feel my feet. 
      Also brain fog is gone. This is a Life Changer for me & a true Miracle!!! I will never be without it! 
      THANK YOU...`
    },
    {
      image: '../../../../../assets/images/image_2024_08_14T11_04_46_456Z.png',
      name: 'Thayne Shively',
      message: `I have had fibromyalgia & neuropathy for more than 25 years. I have been taking this 
      zeolite clinoptilolate product for 1 month. I have No fibro pain & I’m beginning to feel my feet. 
      Also brain fog is gone. This is a Life Changer for me & a true Miracle!!! I will never be without it! 
      THANK YOU...`
    }
    // Add more testimonials as needed
  ];
  selectedOption: string = 'type-one-time';
  selectedFrequency: string;  
  selectedDateOption: string = '2';  // Default selected option for dropdown
  selectedStartDate: Date = new Date();  // Default selected date (September 1, 2024)
  public commonData;


  constructor(
    private titleService: Title,
    private translate: TranslateService,
    public configService: ConfigService,
    private route: ActivatedRoute,
    public productsService: ProductService,
    public dialog: MatDialog,
    private router: Router,
    private cartService: CartService,
    private cart1Service: Cart1Service,
    public itemsService: ProductService,
    public itemsListService: ItemsListService,
    public activateroute: ActivatedRoute,
    public utilityService: UtilityService,
    public persistentService: PersistentService,
    public autoshipConfigurationService: AutoshipConfigurationService,
    public companyService: CompanyService,
    private accountService: AccountService,
    private userService: UserService,
    private notificationService: NotificationService,
    private sidebarNavService: SideNavBarService,
  ) {
    // force route reload whenever params change;
    
    this.itemTypes = {
      pack: {
        getItemsCall: "GetEnrollmentKitItems",
        itemQuantity: "packQuantity",
        cartItems: "selectedPacks",
        itemserviceCall: "getPackItems",
      },
      order: {
        getItemsCall: "GetProducts",
        itemQuantity: "orderQuantity",
        cartItems: "selectedOrderItems",
        itemserviceCall: "getOrderItems",
      },
      autoship: {
        getItemsCall: "GetAutoshipItems",
        itemQuantity: "autoshipQuantity",
        cartItems: "selectedAutoOrderItems",
        itemserviceCall: "getAutoshipItems",
      },
    };
    
    this.env = environment;

    this.itemsService.updateProductDetailPricing.subscribe((res) => {
      if(res) {
        if(window.location.pathname.includes('product/')) {
          this.refreshProduct();
        }
      }
    });
    this.commonData = this.configService.getConfig();
  }

  ngOnInit() {
    if(this.product.AllowAutoship) {
      this.itemPurchaseSelectionType = 'type-subscribe';
    } else {
      this.itemPurchaseSelectionType = 'type-one-time';
    }

    this.translate.get("global_Company_Title").subscribe((text: string) => {
      this.titleService.setTitle(
        this.translate.instant("pagetitle_productdetails") + " | " + text,
      );
    });
    this.autoshipConfigurationService.init();
    this.setProductsDetails();
  }
  count = 0;

  getproductname(productname){
    let text = productname;
    let extractedWords = text?.split(" - ")[0];
    return extractedWords      
    
  }

  increment() {
    this.count++;
  }

  decrement() {
    if (this.count > 0) {
      this.count--;
    }
  }

  dateFilter = (d: Date | null): boolean => {
    const date = (d || new Date()).getDate();
    const currentMonth = (d || new Date()).getMonth();
    const currentYear = (d || new Date()).getFullYear();

    const today = new Date();
    const thisMonth = today.getMonth();
    const thisYear = today.getFullYear();
    const todayDate = today.getDate();

    // Allow dates from the 1st to the 10th of future months
    if (currentYear > thisYear) {
        return date >= 1 && date <= 10; // Allow all dates from 1st to 10th in future years
    } else if (currentYear === thisYear) {
        if (currentMonth > thisMonth) {
            return date >= 1 && date <= 10; // Allow all dates from 1st to 10th in future months of the current year
        } else if (currentMonth === thisMonth) {
            // If today's date is greater than 10, disable the current month
            return todayDate > 10 ? false : date >= todayDate && date <= 10;
        }
    }

    return false; // Exclude all previous months and years
  };
  
  ngAfterViewInit() {
    // this.config = {
    //   observer: true,
    //   direction: "horizontal",
    //   slidesPerView: 3,
    //   spaceBetween: 10,
    //   keyboard: true,
    //   navigation: true,
    //   pagination: false,
    //   grabCursor: true,
    //   loop: false,
    //   preloadImages: false,
    //   lazy: true,
    //   breakpoints: {
    //     480: {
    //       slidesPerView: 1,
    //     },
    //     740: {
    //       slidesPerView: 2,
    //     },
    //     960: {
    //       slidesPerView: 3,
    //     },
    //     1280: {
    //       slidesPerView: 3,
    //     },
    //   },
    // };

    this.updateGallerySwiper();
  
  }

  updateGallerySwiper() {
    this.GalleryThumbImages = {
      spaceBetween: 10,
      slidesPerView: 4,
      loop: false,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      slideActiveClass: 'slide-gallery-active'
    };

    this.GalleryTopImages = {
      spaceBetween: 10,
      loop: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: Object.assign(this.GalleryThumbImages, {el: '.gallery-thumbs'})
      }
    };
  }

  setProductsDetails() {
    this.route.params.subscribe((params) => {

      $(document).ready(() => {
        this.updateGallerySwiper();
      });

      this.ItemID = +params["id"];
      this.activateroute.queryParams.subscribe((queryparam) => {
        const { storeid } = queryparam;
        this.queryparams['storeid'] = storeid;
        this.itemType = queryparam.itemtype || queryparam.itemType;
        this.router.navigate(["."], {
          relativeTo: this.route,
          queryParams: { itemtype: this.itemType, storeid: storeid },
          replaceUrl: true,
        });
        window.scroll(0, 0);

        // For fetching item from custom store
        const request = {};
        if(storeid == 4) {
          this.productsService.allItems = [];
          // this.userService.userServiceModal.customerTypeID = 1;
          this.userService.userServiceModal.customerTypeID = 3;
          request['StoreID'] = storeid;
          request['PriceGroup'] = 1;
        }

        this.productsService.getProduct(this.ItemID, request).subscribe((product) => {

          if(!product) {
            this.router.navigate(['/products/all']);
          }

          this.product = product ? [product] : [];
          // console.log("product",this.product);
          /** Modify the Images URLs ( push all urls in one KEY { AllImagesUrls} ) */

          try {

            const item = this.product[0];
            console.log("item",item)

            //Suggested Products
            this.getRelatedProducts(item);

            // Parse HTML to make Tabs
            (item?.Specifications && this.fetchAndParseTabs(item?.Specifications));

            if(item && item.ImageUrl) {
              item['AllImagesUrls'] = [];
              // item?.AllImagesUrls?.push({ url: item.ImageUrl});
              if(item.Images.length > 0) {
                item.Images.map((image) => item.AllImagesUrls.push({ url: image?.Path}));
              }
            }
            this.product[0] = item;

            // Set Short description
            $(document).ready(function(){
              const Specifications = document.querySelector(".short-description");
              const shortDescription2 = document.querySelector(".product-Text-description");
              Specifications && (Specifications.innerHTML = item?.Specifications || item?.Description || "");
              item?.Description && shortDescription2 && (shortDescription2.innerHTML = item.Description);
            });


          } catch (error) {
            //
          }

          

          /** -------------------------------------------------------- */


          this.itemsService.selectedOrderItems.filter((x) => {
            if (x.ItemID == this.product[0]?.ItemID) {
              this.product.Quantity = x.Quantity;
            }
          });
          const dialogData = new ConfirmDialogModel(
            this.translate.instant("update_product_title"),
            this.translate.instant("update_product_text"),
            this.product.length > 0 ? this.translate.instant("NO") : "",
            this.product.length > 0
              ? this.translate.instant("YES")
              : this.translate.instant("ok_btn"),
          );
          if (this.product.length == 0) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              maxWidth: "400px",
              data: dialogData,
              autoFocus: false,
            });
            dialogRef.afterClosed().subscribe((dialogresult) => {
              if (dialogresult) {
                this.router.navigateByUrl("products/all");
              }
            });
          }
          this.OrderQuantityCount =
            product && product.Quantity ? product.Quantity : 1;
          this.onItemsSucces(this.product);
          this.setUpOrderOptions(this.product);

        });
      });
      this.defaultImage = {
        Path: "assets/images/noimage.png",
        Description: this.product ? this.product.Description : "",
      };
      this.CartType = this.itemType || "order";
      this.type = this.CartType;
    });
  }

  refreshProduct() {
    const request = {
      CategoryId: "all",
    };

    this.productsService.getProductByCategory(request).subscribe((products) => {
        if(products) {
          this.setProductsDetails();
        }
    });
  }

  public onItemsSucces(result) {

  
    this.itemType =
      this.type === "order"
        ? "order"
        : this.type == "pack"
          ? "pack"
          : "autoship";
    this.itemsService[this.itemType] = result.map((item) => {
      item.Price = item.Price || item.Prices[0].Price;
      return item;
    });
    this.itemsListService.products = this.itemsService[this.itemType];
    this.itemsListService.type = this.type;
    const selectedItem = this.product;
    this.products = result.map((item) => {
      item.Price = item.Price || item.Prices[0].Price;
      return item;
    });
    if (selectedItem) {
      this.products = _.filter(this.products, (product) => {
        return product.ItemID != selectedItem.ItemID;
      });
    }
    if (this.ItemID) {
      let isItemExists = false;
      _.each(result, (item) => {
        if (item.HasOptions) {
          _.each(item.OptionsMap, (value) => {
            if (value.ItemId == this.ItemID) {
              item.ItemID = this.ItemID;
              return;
            }
          });
        }
        if (
          isNaN(this.ItemID)
            ? (item.ProductName || "").toLowerCase().replace(/\s/g, "") ==
              this.ItemID.toLowerCase().replace(/\s/g, "")
            : item.ItemID == this.ItemID
        ) {
          isItemExists = true;
          this.product = this.transformItem(item);
          this.OrderQuantityCount = selectedItem
            ? selectedItem.Quantity || 1
            : 1;
          item.Quantity = selectedItem ? selectedItem.Quantity || 1 : 1;
          localStorage.setItem("params.type", this.type);
          localStorage.setItem(
            "params.productList",
            JSON.stringify(this.products),
          );
        }
      });
      if (!isItemExists) {
        this.router.navigate(["/Products"]);
      }
    }
  }
  public transformItem(item) {
    this.isShowShareOptions = false;
    item.Price =
      item.Price || item.Price === 0
        ? item.Price
        : item.Prices && item.Prices[0] && item.Prices[0].Price;
    item.Images = item.Images || [];
    if (item) {
      Object.keys(item.Prices).forEach((p: any) => {
        if (
          p.CurrencyCode &&
          p.CurrencyCode.toLowerCase() === "rwd" &&
          p.Price > 0
        ) {
          item.UsePoints = true;
        }
      });
    }
    if (
      item.LargeImageUrl &&
      !_.find(item.Images, { Path: item.LargeImageUrl })
    ) {
      item.Images.unshift({
        Description: item.Description,
        Path: item.LargeImageUrl,
      });
    } else if (item.Image && !_.find(item.Images, { Path: item.Image })) {
      item.Images.unshift({
        Description: item.Description,
        Path: item.Image,
      });
    }
    this.isShowShareOptions = true;
    return item;
  }

  public selectImage(index) {
    this.bigProductImageIndex = index;
  }

  public increaseQuantity() {
    if (this.OrderQuantityCount < 99) {
      this.OrderQuantityCount++;
    }
  }

  public decreaseQuantity() {
    if (this.OrderQuantityCount > 1) {
      this.OrderQuantityCount--;
    }
  }

  getRelatedProducts(product) {
    const storeID = product.CategoryId === 36 ? 4 : null;
    this.productsService
      .getRelatedProducts(product, 4, storeID)
      .subscribe((result) => {
        const Suggestedproducts = result;
        console.log("Suggestedproducts",Suggestedproducts)
        this.relatedProducts = Suggestedproducts.filter((element) => element.ItemID !== product.ItemID);
        this.relatedProducts.length > 3 && this.relatedProducts.pop();
      });
  }

  // Add to cart
  public addToCart(type, product, purchaseMode?) {

    if(type == "check-type") {
      this.itemPurchaseSelectionType == 'type-subscribe' 
        ? (type = 'autoship') 
        : this.itemPurchaseSelectionType == 'type-one-time' 
        ? (type = 'order') : 'order';
    }

    if(this.itemType == 'pack') {
      type = "pack";
    }

    this.currentQuantity(type, product);

    // If Item is from installment
    if(purchaseMode == "installment") {
      this.buyItemWithInstallment(product);
      return;
    }

    this.product.Quantity = this.OrderQuantityCount;

    if (type == "autoship" || product.ItemID == 'e97') {
      this.cart1Service.orderQuantity[product.ItemID] = product.Quantity;
      this.cart1Service.autoshipQuantity[product.ItemID] = product.Quantity;

      this.addToBothCart(product);
    } 
    else if (type == "order") {
      this.cart1Service.orderQuantity[product.ItemID] = product.Quantity;
      this.cart1Service.addToCart(product, true, this.getItemCode(product), false, true, false, false);
    }  
    else if (this.itemType == 'pack') {
      sessionStorage.setItem('IsMarketCountrySelected', 'true');
      this.cart1Service.addToCart(product, true, this.getItemCode(product), false, true, type === "pack", false);
    }

    this.sidebarNavService.showsideBarSummary = true;

  }

  addToBothCart(product) {
    // Autoship
    this.cart1Service.addToCart(product, true, this.getItemCode(product), true, true, false, false);

    // Order
    this.cart1Service.addToCart(product, true, this.getItemCode(product), false, true, false, false);
  }

  public filterItemOptionID(type, product) {
    const optionMap = [];
    product.OptionsMap.forEach((x) => {
      optionMap.push(x.Key);
    });
    const selectoption = product.selectedOptions
      .replace("[", "")
      .replace("]", "")
      .split(",");
    selectoption.forEach((element) => {
      optionMap.filter((x, index) => {
        if (!x.includes(element.trim())) {
          optionMap.splice(index, 9999);
        }
      });
    });
    product.OptionsMap.filter((key) => {
      if (key.Key == optionMap[0]) {
        //
      }
    });
    this.addToCart(type, product);
  }
  // Add to cart
  public buyNow(product, quantity) {
    if (quantity > 0) {
      this.cartService.addToCart(product, parseInt(quantity, 10));
      this.router.navigate(["/checkout"]);
    }
  }

  public onMouseMove(e) {
    if (window.innerWidth >= 1280) {
      const image = e.currentTarget;
      const offsetX = e.offsetX;
      const offsetY = e.offsetY;
      const x = (offsetX / image.offsetWidth) * 100;
      const y = (offsetY / image.offsetHeight) * 100;
      const zoomer = this.zoomViewer.nativeElement.children[0];
      if (zoomer) {
        zoomer.style.backgroundPosition = x + "% " + y + "%";
        zoomer.style.display = "block";
        zoomer.style.height = image.height + "px";
        zoomer.style.width = image.width + "px";
      }
    }
  }

  onMouseLeave() {
    this.zoomViewer.nativeElement.children[0].style.display = "none";
  }

  getPrice(item, quantity) {
    if (quantity < 1) {
      this.OrderQuantityCount = 1;
    }

    // Subscription Price
    if(this.itemPurchaseSelectionType == "type-subscribe") {
      return item && item.AllowAutoship ? (item.AutoshipPrice * quantity) : (item.Price || (item.Prices && item.Prices[0].Price)) * quantity;
    }

    return item
      ? (item.Price || (item.Prices && item.Prices[0].Price)) * quantity
      : 0;
  }
  checkOptions(option) {
    let count = 0;
    this.value.forEach((item) => {
      if (item) {
        count++;
      }
    });

    this.isOptionsSelected = count === option.length;
  }
  currentQuantity(type, item) {
    type = type || this.itemType;
    this.cart1Service[this.itemTypes[type].itemQuantity] =
      this.cart1Service[this.itemTypes[type].itemQuantity] || {}; // TODO::check if needed
    this.cart1Service[this.itemTypes[type].itemQuantity][
      this.getItemCode(item)
    ] = this.OrderQuantityCount;
  }

  setUpOrderOptions(item) {
    if (!this.orderOptions) {
      this.orderOptions = {};
    }

    const dict = this.orderOptions;

    // If editing an item with selected customizations, find them and set the dropdowns
    let optionsMap;
    _.each(item.OptionsMap, (val) => {
      val.OptionNames = val.Key.split("|");
    });
    if (item.selectedOptions) {
      item.OptionsMap.some((optMap) => {
        if (optMap.ItemId === (item.KitCustomItemCode || item.ItemID)) {
          optionsMap = optMap.OptionNames;
          return true;
        }
        return false;
      });
    }

    // Otherwise, default to the first options map
    const optionMapNames = item.OptionsMap
      ? item.OptionsMap.map((optMap) => {
          return optMap.OptionNames;
        })
      : [];

    if (!item.selectedOptions || !optionsMap) {
      optionsMap = optionMapNames[0];
    }

    // Loop through the OptionsMap
    let optionVal;
    let optionValues;
    for (const index in optionsMap) {
      if (Object.prototype.hasOwnProperty.call(optionsMap, index)) {
        optionVal = optionsMap[index];
        // For each option in the OptionsMap, loop through the ItemOptions
        for (const itemIndex in item.ItemOptions) {
          // Get all the values of the ItemOption
          if (
            Object.prototype.hasOwnProperty.call(item.ItemOptions, itemIndex)
          ) {
            optionValues =
              item.ItemOptions[itemIndex].Values.map(getOptionValueName);
            // If one of them is the value from the OptionsMap, use it
            const option = item.ItemOptions[itemIndex];
            if (
              !!~optionValues.indexOf(optionVal) &&
              !dict[this.getOrderOptionKey(item, option)]
            ) {
              dict[this.getOrderOptionKey(item, option)] = optionVal;
              break;
            }
          }
        }
      }
    }

    function getOptionValueName(val) {
      return val.Option;
    }
  }

  // change active image
  setActiveImage(image) {
    this.ezpModel = {
      small: image.Path || this.defaultImage.Path,
      large: image.Path || this.defaultImage.Path,
    };
    setTimeout(() => {
      this.activeImage = image;
    }, 1);
  }

  selectOption(item, option, value, isKitItem) {
    option.selected = value.Option;
    this.orderOptions[this.getOrderOptionKey(item, option)] = value.Option;
    const tempOptData = [];
    Object.keys(this.orderOptions).forEach((optdata) => {
      tempOptData.push(optdata);
    }),
      item.OptionsMap.forEach((itemOptdata) => {
        if (
          JSON.stringify(tempOptData.sort()) ===
          JSON.stringify(itemOptdata.OptionNames.sort())
        ) {
          let tempimgurl = item.SmallImageUrl;
          if (itemOptdata.Image) {
            tempimgurl = item.SmallImageUrl.substr(
              0,
              item.SmallImageUrl.lastIndexOf("/"),
            ).concat(itemOptdata.Image);
          }
          const tempimage = {
            Path: tempimgurl,
          };
          this.setActiveImage(tempimage);
        }
      });
    // item.OptionsMap.filter((itemOptdata) => {
    this.itemsService.selectedOrderItems.filter((item) => {
      item.ItemOptions.filter((i) => {
        if (i.selected == option.selected) {
          this.OrderQuantityCount = item.Quantity;
        }
      });
    });
    // })

    if (isKitItem) {
      item.KitCustomItemCode = this.getItemCode(item);
    }
  }

  setInitialItemOption(option, value) {
    const defaultOption =
      this.orderOptions[this.getOrderOptionKey(this.product, option)] ||
      option.Option;
    if (value.Option === defaultOption) {
      option.selected = value.Option;
    }
  }

  getOrderOptionKey(item, option) {
    return item.ItemID + "__" + option.Option;
  }

  // Determine what values are available based on what's already been selected
  getValidValues(item, option, values) {
    // Get the already-selected option values (except this one and values from other items)
    const currentOption = this.getOrderOptionKey(item, option);
    const otherOptions = [];
    for (const key in this.orderOptions) {
      if (
        key === currentOption ||
        item.ItemID.toString() !== key.split("__")[0]
      ) {
        continue;
      }
      otherOptions.push(this.orderOptions[key]);
    }
    // Whittle down the OptionMaps to the ones that have all the already-selected options
    const optionMapNames = item.OptionsMap
      ? item.OptionsMap.map((optMap) => {
          return optMap.OptionNames;
        })
      : [];
    const validOptionMaps = optionMapNames.filter((optNames) => {
      // For each OptionMap, ensure that it contains all the other selected options
      let optionValue1;
      for (const index in otherOptions) {
        if (Object.prototype.hasOwnProperty.call(otherOptions, index)) {
          optionValue1 = otherOptions[index];
          if (~optNames.indexOf(optionValue1)) {
            continue;
          }
          return false;
        }
      }
      return true;
    });

    // Union the validOptionMaps
    const validOptionValues = validOptionMaps.reduce((arr, optMap) => {
      let optionName;
      for (const index in optMap) {
        if (Object.prototype.hasOwnProperty.call(optMap, index)) {
          optionName = optMap[index];
          if (!~arr.indexOf(optionName)) {
            arr.push(optionName);
          }
        }
      }
      return arr;
    }, []);

    // Set `isAvailable: true` on each option that can be found in validOptionNames, and `isAvailable: false` on the others
    let optionValue;
    for (const index in values) {
      if (Object.prototype.hasOwnProperty.call(values, index)) {
        optionValue = values[index];
        optionValue.isAvailable = !!~validOptionValues.indexOf(
          optionValue.Option,
        );
      }
    }

    // return the original values
    return values.filter((itm) => {
      return itm.isAvailable;
    });
  }

  getOptionsText(optionValArray) {
    return "[" + optionValArray.join(", ") + "]";
  }

  getItemCode(item) {
    // If there are no customizations, just return the item code
    if (!item.ItemOptions || !item.ItemOptions.length || !item.HasOptions) {
      return this.product.ItemID;
    }

    // If there are customizations, find the right OptionsMap and use its code
    const optionValues = [];
    for (const key in this.orderOptions) {
      if (
        !Object.prototype.hasOwnProperty.call(this.orderOptions, key) ||
        item.ItemID.toString() !== key.split("__")[0]
      ) {
        continue;
      }

      optionValues.push(this.orderOptions[key]);
    }

    optionValues.sort();

    item.selectedOptions = this.getOptionsText(optionValues);

    if (item.HasKitOptions) {
      item.KitGroups.forEach((kitGroup) => {
        kitGroup.Items.forEach(this.getItemCode);
      });
    }

    let mapping;
    for (const index in item.OptionsMap) {
      if (Object.prototype.hasOwnProperty.call(item.OptionsMap, index)) {
        mapping = item.OptionsMap[index];
        mapping.OptionNames.sort();
        if (_.isEqual(optionValues, mapping.OptionNames)) {
          return mapping.ItemId;
        }
      }
    }

    throw new Error(
      "Error: " +
        item.ProductName +
        " does not have a mapping for " +
        optionValues.toString() +
        ".",
    );
  }

  openSelect(event) {
    this.event = event || this.event;
    // this is due to select input fields are not consistant in some browser
    if (window.screen.availWidth < 600 && !event) {
      setTimeout(() => {}, 100);
    }
  }
  gotocategory(category: string) {
    const formattedCategory = category.replace(/\s+/g, '-').toLowerCase();
    this.router.navigate([`/category/${formattedCategory}`]);
  }
  navigateToProductDetails(productId: number) {
    this.router.navigate([`/product/${productId}`]);
  }

  hideAddToCart(item, isAutoShip = false) {
    if (!this.utilityService.isEmptyObject(item)) {
      const itemId = this.getItemCode(item);
      let isOrderAllow = false;
      if (item.OptionsMap?.length > 0 && isAutoShip) {
        _.each(item.OptionsMap, (value) => {
          if (value.ItemId == itemId) {
            if (isAutoShip) {
              isOrderAllow = item.AllowAutoship; // ? value.AllowAutoship : false;
            } else {
              isOrderAllow = value.isAllowOrder;
            }
          }
        });
      } else if (isAutoShip) {
        return item.AllowAutoship;
      } else {
        return item.isAllowOrder;
      }
      return isOrderAllow;
    }
  }
  getAllProductsCategories() {
    this.selectedCategory = { CategoryId: "all" };
    this.productsService
      .getProductByCategory(this.selectedCategory)
      .subscribe((products) => {
        this.productsService.orders = products.map((item) => {
          item.Price =
            item.Price ||
            (item.Prices && item.Prices[0] && item.Prices[0].Price);
          return item;
        });

        const uniqueItems = _.uniqBy(products, (x) => x.CategoryId);
        let uniqueRequireProduct = [];
        if (
          this.configService.localSettings.Global.CategoriesToFetch?.length > 0
        ) {
          uniqueItems.filter((x) => {
            if (
              this.configService.localSettings.Global.CategoriesToFetch.indexOf(
                x.Category,
              ) > -1
            ) {
              uniqueRequireProduct.push(x);
            }
          });
        } else {
          uniqueRequireProduct = [...uniqueItems];
        }
        this.itemsListService.selectedCategories = {};
        if (this.selectedCategory.Category == "all") {
          this.itemsListService.selectedCategories["all"] = true;
        }
        this.itemsListService.categoryList = _.map(
          uniqueRequireProduct,
          (object) => {
            return _.pick(object, ["CategoryId", "Category"]);
          },
        );
        this.itemsListService.products = this.productsService.orders;
        this.itemsListService.type = "order";
        this.itemsListService.getItemsByCategory(
          this.selectedCategory.Category,
        );

        this.products = this.itemsListService.productList.slice(0.8);
        this.allItems = this.itemsListService.categoryList;
        this.healthBeautyProducts();
      });
  }
  healthBeautyProducts() {
    this.itemsListService.getItemsByCategory(this.allItems[0].Category);
    this.healthBeautyProduct = this.itemsListService.productList.slice(0, 3);
  }
  addProduct(item) {
    if (item.ItemOptions.length > 0) {
      this.router.navigate(["/product", item.ItemID]);
    } else {
      this.OrderQuantityCount = item && item.Quantity ? item.Quantity : 1;
      if (this.cart1Service["orderQuantity"][item.ItemID] >= 1) {
        this.increaseQuantiy("order", item);
      } else {
        this.cart1Service["orderQuantity"][item.ItemID] =
          this.OrderQuantityCount;
        this.cart1Service.addToCart(
          item,
          true,
          item.ItemID,
          false,
          false,
          false,
          true,
        );
      }
    }
  }
  increaseQuantiy(type, item) {
    this.cart1Service.increaseQuantiy(item, type == "autoship", type == "pack");
  }
  mouseEnter(index) {
    document.getElementById("btn" + index).classList.add("faded-in");
    document.getElementById("btn" + index).classList.remove("faded-out");
    setTimeout(() => {
      document.getElementById("btn" + index).style.opacity = "1";
      // document.getElementById('product'+index).style.opacity = "0"
      // document.getElementById('products'+index).style.opacity = "1"
    }, 590);
  }
  mouseOut(index) {
    document.getElementById("btn" + index).classList.add("faded-out");
    //  document.getElementById('product'+index).classList.remove("productIn")
    // if (index < 3) {
    //   (<HTMLImageElement>document.getElementById('product'+index)).src = this.productImages[index].url;
    // } else if(index < 10){
    //   (<HTMLImageElement>document.getElementById('product'+index)).src = this.mensProduct[(index-4)].url;
    // }else{
    //   (<HTMLImageElement>document.getElementById('product'+index)).src = this.newProduct[(index-10)].url;

    // }
    setTimeout(() => {
      document.getElementById("btn" + index).style.opacity = "0";
      // document.getElementById('products'+index).style.opacity = "0"
      // document.getElementById('product'+index).style.opacity = "1"
    }, 590);
    document.getElementById("btn" + index).classList.remove("faded-in");
  }
  goToShop(id) {
    this.router.navigate(["/product", id]);
    window.scroll(0, 0);
  }
  goToslider(id) {
    // this.dialog.open(FlickityComponent, {
    //   data: { id: id },
    //   disableClose: true,
    //   panelClass: "slider-dialog",
    //   autoFocus: false,
    // });
  }

  isInstallmentAllowed(product) {
    let isAllowed = false;
    if(product.Custom?.Field1?.endsWith(`${product.SKU}-i`) && product.Custom?.Field2?.endsWith(`${product.SKU}-ins`)) {
      isAllowed = true;
    }
    return isAllowed;
  }

  buyItemWithInstallment(product) {
    if(this.isInstallmentAllowed(product)) {
      //
      const item1 = this.itemsService.allItems?.find((item) => item.SKU == `${product.SKU}-i`);
      const item2 = this.itemsService.allItems?.find((item) => item.SKU == `${product.SKU}-ins`);

      if(!item1 || !item2) {
        this.notificationService.error("Error", "Something went wrong, please try without installments");
      }

      // Inserting record for future reference
      if(!this.cart1Service.ReplacementsItemsInCart['ItemsMapping'][`${product.SKU}`]) {
        this.cart1Service.ReplacementsItemsInCart['ItemsMapping'][`${product.SKU}`] = [item1, item2];
      }

      if(!this.cart1Service.ReplacementsItemsInCart['PeriodMapping'][`${product.SKU}`]) {
        this.cart1Service.ReplacementsItemsInCart['PeriodMapping'][`${product.SKU}`] = this.getInstallmentsNumber(product);
      }

      localStorage.setItem("cart.replacementItems",
        JSON.stringify(this.cart1Service.ReplacementsItemsInCart),
      );
                                                                                                         
      // Normal Add to cart ( item 1 and item 2 )
      this.cart1Service.addToCart(item1, true, item1.ItemID, false, false, false, false);

      setTimeout(() => {
        this.cart1Service.addToCart(item2, true, item2.ItemID, false, false, false, false);
      }, 2500);

      // Autoship Add to cart ( for item 2)
      setTimeout(() => {
        this.cart1Service.addToCart(item2, true, item2.ItemID, true, false, false, false);
      }, 1500);

      this.sidebarNavService.showsideBarSummary = true;

    }
  }
  
  RedirectToDetailPage(){
    const ProductCode = this.activateroute.snapshot.paramMap.get('id');
    this.router.navigate(["/product/", ProductCode]);
  }

  ngOnDestroy() {
    this.OrderQuantityCount = 1;
    this.cart1Service.resetItemQuantities();
  }

  getInstallmentsNumber(item) {
    const Field1 = item.Custom?.Field1?.split("_");
    return (Field1 && (+Field1[0])) || null;
  }

  todaysInstallmentAmount(product, OrderQuantityCount) {
    const price = this.getPrice(product, OrderQuantityCount);
    const count = this.getInstallmentsNumber(product);

    if( price > 0 && count > 0) {
      return (price / count) || 0;
    }

    return 0;
  }

  copyProductLink() {
    const url = window.location.href;
    navigator.clipboard.writeText(url)
    .then(() => {
      this.notificationService.success("Success", "Link copied to clipboard!");
    });
  }

  AddSuggestedProductToCart(item) {

    // Fetching that product from ALL products, then adding.

    const product = this.itemsService.allItems.find((i) => i.ItemID == item.ItemID);
    if(!product) {
      this.router.navigate(['/product', item.ItemID]);
      return;
    }

    this.cart1Service.addToCart(product, true, "", false, false, false, false);
    this.sidebarNavService.showsideBarSummary = true;
  }

  fetchAndParseTabs(SerializedHTML){

    try {

      const parser = new DOMParser();
      const doc = parser.parseFromString(SerializedHTML, 'text/html');
  
      const tabSections = doc.querySelectorAll('.5star-tab-section');
      const tabData = [];
  
      tabSections.forEach(section => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const title = section.querySelector('.5star-tab-title')?.innerText;
        const content = section.querySelector('.5star-tab-content')?.innerHTML || "";
        tabData.push({ title, content });
      });
  
      console.log("tabData", tabData);
      
      this.ProductSpecification = tabData;

    } catch(err) {
      this.ProductSpecification = [];
    }

  }
  setFrequency(event) {
    this.commonData.FrequencyTypes.some((freq) => {
      if (
        freq.ID == event.value //this.persistentService.retailData.Autoship.FrequencyTypeID
      ) {
        this.persistentService.retailData.Autoship.FrequencyTypeID = freq.ID;
        this.persistentService.retailData.Autoship.FrequencyTypeID = freq.ID;
        this.persistentService.retailData.Autoship.FrequencyTypeDescription = freq.Description;
        this.persistentService.retailData.isChanged = true;
        return;
      }
    });
  }
}