<!-- customerTypeID = {{ userService.customerTypeID }} -->

<!-- Top Header -->
 <div class="font-secondary w-full flex px-3 py-2 bg-[#F8F9FA] justify-end items-center">

    <div class="flex justify-end items-center gap-x-2 cursor-pointer" [matMenuTriggerFor]="CountriesMenu">
      <img class="w-4 h-auto gap-2" [src]="'https://directscalestring.directscale.com/flags/4x3/' + selectedCountry.toLowerCase() + '.svg'" alt="">
      <span>{{ selectedCountry.toUpperCase() || 'US' }} </span>
      <svg class="w-4 h-4 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9-7 7-7-7"/>
      </svg>
    </div>

    <mat-menu #CountriesMenu="matMenu" class="CountriesMenu">
      <div class="p-4">
        <div (click)="updatecountry(country)" *ngFor="let country of allowedCountries" class="mb-1 cursor-pointer flex gap-2">
          <img class="w-4 h-auto gap-2" [src]="'https://directscalestring.directscale.com/flags/4x3/' + country.CountryCode.toLowerCase() + '.svg'" alt="">
          <span>{{ country.CountryName }}</span>
        </div>
      </div>
    </mat-menu>

 </div>

<div class="font-secondary flex flex-col items-center px-16 py-5 border-b shadow-sm border-b-stone-300 max-md:px-5">
  <div class="flex flex-col w-full max-w-[1320px] max-md:max-w-full">
    <div class="flex gap-5 justify-between w-full max-md:flex-wrap max-md:max-w-full">
      <div class="flex justify-center items-center">
        <img routerLink="/home" loading="lazy"
          srcset="https://cdn.builder.io/api/v1/image/assets/TEMP/c49f2f293b7df500618ddaa57fcd25ca884111c8b038729af2b274d5e38a9e08?apiKey=9d06630e091845a9863c65f5c77fcc83&&apiKey=9d06630e091845a9863c65f5c77fcc83&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c49f2f293b7df500618ddaa57fcd25ca884111c8b038729af2b274d5e38a9e08?apiKey=9d06630e091845a9863c65f5c77fcc83&&apiKey=9d06630e091845a9863c65f5c77fcc83&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c49f2f293b7df500618ddaa57fcd25ca884111c8b038729af2b274d5e38a9e08?apiKey=9d06630e091845a9863c65f5c77fcc83&&apiKey=9d06630e091845a9863c65f5c77fcc83&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c49f2f293b7df500618ddaa57fcd25ca884111c8b038729af2b274d5e38a9e08?apiKey=9d06630e091845a9863c65f5c77fcc83&&apiKey=9d06630e091845a9863c65f5c77fcc83&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c49f2f293b7df500618ddaa57fcd25ca884111c8b038729af2b274d5e38a9e08?apiKey=9d06630e091845a9863c65f5c77fcc83&&apiKey=9d06630e091845a9863c65f5c77fcc83&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c49f2f293b7df500618ddaa57fcd25ca884111c8b038729af2b274d5e38a9e08?apiKey=9d06630e091845a9863c65f5c77fcc83&&apiKey=9d06630e091845a9863c65f5c77fcc83&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c49f2f293b7df500618ddaa57fcd25ca884111c8b038729af2b274d5e38a9e08?apiKey=9d06630e091845a9863c65f5c77fcc83&&apiKey=9d06630e091845a9863c65f5c77fcc83&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c49f2f293b7df500618ddaa57fcd25ca884111c8b038729af2b274d5e38a9e08?apiKey=9d06630e091845a9863c65f5c77fcc83&&apiKey=9d06630e091845a9863c65f5c77fcc83"
          class="aspect-[2.94] w-[150px] md:w-[233px] cursor-pointer" />
      </div>
      <div class="flex gap-4 my-auto text-lg items-center font-medium text-black">
        <!-- Search -->
        <!-- <svg class="w-6 h-6 text-gray-800 cursor-pointer" aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
            d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
        </svg> -->
        <!-- Account -->
        <div class="hidden md:flex gap-2 items-center cursor-pointer" [matMenuTriggerFor]="AccountsMenu">
          <svg class="w-6 h-6 text-gray-700 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
            width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd"
              d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z"
              clip-rule="evenodd" />
          </svg>
          <div class="self-stretch">My Account</div>
          <svg class="w-4 h-4 text-gray-700 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
            width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m19 9-7 7-7-7" />
          </svg>
        </div>
        <mat-menu #AccountsMenu="matMenu" class="AccountsMenuHeader">
          <div class="p-4">
            <div class="mb-1 cursor-pointer" (click)="logout()" *ngIf="isLoggedIn()">LogOut</div>
            <a href="https://office2.avinihealth.com/" target="_blank"><div class="mb-1 cursor-pointer" *ngIf="!isLoggedIn()">Sign In</div></a>
            <div class="mb-1 cursor-pointer" routerLink="/signup" *ngIf="!isLoggedIn()">Sign Up</div>
          </div>
        </mat-menu>
        <!-- Cart -->
        <!-- <svg class="w-6 h-6 text-gray-800  cursor-pointer" aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-8.5-3h9.25L19 7H7.312" />
        </svg> -->
        <div class="w-6 h-6 text-gray-800  cursor-pointer ml-5">
          <app-shopping-widgets [shoppingCartItems]="shoppingCartItems" [isWhite]="isTop"></app-shopping-widgets>
        </div>
        <!-- Mobile Toggler -->
        <div class="md:hidden">
          <svg (click)="drawer.toggle()" class="w-6 h-6 text-gray-800  cursor-pointer" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M5 7h14M5 12h14M5 17h14" />
          </svg>
        </div>
      </div>
    </div>
    <div class="hidden md:flex flex-col justify-center mt-6 text-lg font-medium text-slate-700 max-md:max-w-full">
      <div class="flex gap-4 justify-center gap-x-10 w-full max-md:flex-wrap max-md:max-w-full">
        <div class="cursor-pointer" routerLink="/home" routerLinkActive="text-teal-600">Home</div>
        <div [matMenuTriggerFor]="ProductsMenu" class="cursor-pointer flex gap-2 whitespace-nowrap">
          <div>Products</div>
          <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/5af849f016feee9f42338b3fa91645057473d6a87cdb5238cfd3101c4ead545a?apiKey=9d06630e091845a9863c65f5c77fcc83&&apiKey=9d06630e091845a9863c65f5c77fcc83"
            class="shrink-0 my-auto aspect-[1.85] fill-slate-700 w-[13px]" />
        </div>
        <mat-menu #ProductsMenu="matMenu" class="ProductsMenuHeader">
          <div class="p-4">
            <div *ngFor="let category of productsService.retailStoreCategories"
              (click)="navigateToCategory(category.Name)" class="mb-1 cursor-pointer">
              {{ category.Name }}
            </div>
          </div>
        </mat-menu>
        <div class="cursor-pointer" routerLinkActive="text-teal-600" routerLink="/testimonials">Testimonials</div>
        <div class="cursor-pointer" routerLinkActive="text-teal-600" routerLink="/scientific-research">Scientific
          Research</div>
        <div class="cursor-pointer" routerLinkActive="text-teal-600" routerLink="/event-calendar">Event Calendar</div>
        <div class="cursor-pointer" routerLinkActive="text-teal-600" routerLink="/app">App</div>
        <div class="cursor-pointer" routerLinkActive="text-teal-600" routerLink="/contact">Contact Us</div>
        <div class="cursor-pointer" routerLinkActive="text-teal-600" routerLink="/enrollment/welcome" *ngIf="!isLoggedIn()">Join Now</div>
        <!-- <div class="cursor-pointer" routerLinkActive="text-teal-600"><a href="https://office2.avinihealth.com/" target="_blank">Back Office</a> </div> -->
      </div>
    </div>
  </div>
</div>

<!-- Mobile Side Nav Drawer -->
<mat-sidenav-container>
  <mat-sidenav #drawer mode="side" position="end" class="sidenav w-[85%]">
    <div class="font-secondary w-full border-b border-b-stone-300 p-3" (click)="drawer.close()">
      <svg class="w-6 h-6 text-gray-700  cursor-pointer" aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M6 18 17.94 6M18 18 6.06 6" />
      </svg>
    </div>
    <!-- Side Menus -->
    <div>
      <div *ngFor="let menu of mobileSideMenus" class="w-full border-b border-b-stone-300 p-3"
        [ngClass]="(menu.expandedAllowed && menu.expandedState) ? 'flex flex-col' : 'block'">
        <div class="w-full flex justify-start items-center gap-1">
          <div *ngIf="menu.icon === 'account'">
            <svg class="w-6 h-6 text-gray-800 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-width="2"
                d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
          </div>

          <ng-container *ngIf="menu.externalLink; else internalLink">
            <a [href]="menu.externalLink" target="_blank" class="text-base text-gray-700 font-medium cursor-pointer"
              (click)="drawer.close();">{{menu.title}}</a>
          </ng-container>
          <ng-template #internalLink>
            <span *ngIf="menu.path" class="text-base text-gray-700 font-medium cursor-pointer" [routerLink]="menu.path"
              (click)="drawer.close();">{{menu.title}}</span>
          </ng-template>

          <span *ngIf="!menu.path && !menu.externalLink" class="text-base text-gray-700 font-medium cursor-pointer"
            (click)="menu.expandedState = !menu.expandedState">{{menu.title}}</span>

          <svg *ngIf="menu.expandedAllowed" (click)="closeAllExpandedMenus(); menu.expandedState = !menu.expandedState"
            class="w-4 h-4 text-gray-700  cursor-pointer" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m19 9-7 7-7-7" />
          </svg>
        </div>

        <div *ngIf="menu.expandedState" [ngClass]="{'mt-2': menu.expandedAllowed}">
          <div *ngFor="let child of menu.expandedItems" class="w-full px-3 py-1 flex justify-start items-center gap-1">
            <span class="text-base text-gray-700 font-normal cursor-pointer"
              (click)="child.title == 'Log Out' ? logout() : (menu.title == 'Products' ? navigateToCategory(child.title) : navigate(child.path)); drawer.close();">{{child.title}}</span>
          </div>
        </div>
      </div>

    </div>

  </mat-sidenav>

  <mat-sidenav-content>
  </mat-sidenav-content>
</mat-sidenav-container>