import { Component, OnInit } from '@angular/core';
import { CalendarDateFormatter, CalendarEvent, CalendarView } from 'angular-calendar';
import { isSameDay } from 'date-fns/isSameDay';
import { CustomDateFormatter } from './custom-date.provider';
import { ProductService } from '../../shared/services/product.service';
import { Router } from '@angular/router';
import { RestApiService } from '../../shared/services/restapi.service';
@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss'],
  providers:[
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class EventCalendarComponent implements OnInit {

  builder = {
    noBuilderPageForUrl: false,
    options: {
      cacheSeconds: 0,
      cache: true
    },
    data: {
      categories: this.productService.retailStoreCategories || []
    },
    context: {
      navigate : ({ ID, productID, externalLink })=>{
        if(ID > 0) {
          this.router.navigate(["/products", ID]);
        } else if (productID) {
          this.router.navigate(["/product", productID]);
        } else if (externalLink) {
          window.open(externalLink, "_blank");
        }
      }
    }
  };

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  today: Date = new Date();
  days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  events: CalendarEvent[] = [];
  specificDates: { date: Date; content: string,time:string,backgroundColor: string }[] = [
    { date: new Date(2024, 7, 15), content: 'Avini Conference Call', time:'11.00 AM',backgroundColor: '' },
    { date: new Date(2024, 7, 25), content: 'Avini Conference Call', time:'8.00 AM',backgroundColor: '' },
    { date: new Date(2024, 7, 31), content: 'Avini Conference Call', time:'9.00 PM',backgroundColor: '' },
    { date: new Date(2024, 7, 1), content: 'Avini Conference Call', time:'10.00 PM',backgroundColor: '' },
    { date: new Date(2024, 7, 10), content: 'Avini Conference Call', time:'2.00 PM',backgroundColor: '' }
  ];
  randomColors: string[] = [
    'bg-purple-100 text-purple-700',
    'bg-pink-100 text-pink-700',
    'bg-green-100 text-green-700',    
    'bg-blue-100 text-blue-700',
    'bg-yellow-100 text-yellow-700'
  ];
  constructor(private productService: ProductService,
    public router: Router,
    private apiService: RestApiService,
  ) {
  }

  ngOnInit(): void {
    this.assignRandomColors();
    this.getAllCategories();
  }
  getTodayDayName(): string {
    return this.days[this.today.getDay()];
  }

  assignRandomColors(): void {
    this.specificDates.forEach((specificDate, index) => {
      specificDate.backgroundColor = this.randomColors[index % this.randomColors.length];
    });
  }
  // Method to check if the given date matches any of the specific dates
  getSpecificDateContent(date: Date) {
    return this.specificDates.find(sd => isSameDay(sd.date, date));
  }

  getAllCategories() {
    if (this.productService.retailStoreCategories.length > 0) {
      this.getImageUrlFromDescription(this.productService.retailStoreCategories);     
      return this.productService.retailStoreCategories;
    }
    
    this.apiService.GetCategories(3).subscribe((result) => {
      this.productService.retailStoreCategories = result;
      this.getImageUrlFromDescription(this.productService.retailStoreCategories);     
    });
  }

  getImageUrlFromDescription(categories) {

    // Insert Cover Iamge
    categories.forEach(element => {
    
      element.providerDescription = "";
      const translationsArray = element.CategoryTranslations || [];
      const englishTranslation = translationsArray.find((t) => t.LanguageCode == "en");
      let { Description } = englishTranslation || {};
      Description = Description?.split("|") || [];
      element.catImage = Description[0] || "https://avinihealth.corpadmin.directscale.com/CMS/Images/Inventory/no_image.jpg";
      element.providerDescription = Description[1] || "";
    
    });

    this.productService.retailStoreCategories = categories;
    
    // Name Modifications
    categories.forEach((category) => {
      if(category?.Name?.toLowerCase() === "zeolite") {
        category.Name = "Cell Defender";
      }
    });

    // Filter Out these 4 properties
    const hideCategories = {
      11: "Sales Tools & Accessories",
      12: "Travel Packets",
    };

    categories = categories.filter((cat) => !Object.prototype.hasOwnProperty.call(hideCategories, cat.ID));

    this.builder.data = categories;
    this.builder.data['categories'] = categories;
  }
}
