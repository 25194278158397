import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ProductDetailsComponent } from "./products/product-details/product-details.component";
import { ProductNoSidebarComponent } from "./products/product-no-sidebar/product-no-sidebar.component";
import { ProductNewComponent } from "./products/ProductNew/productNew.component";
import { SingleProductDetailsComponent } from "./products/single-product-details/single-product-details.component";

// Routes
const routes: Routes = [
  { path: "products/:category", component: ProductNewComponent },
  {
    path: "product/:id",
    component: ProductDetailsComponent,
    pathMatch: "full",
  }
  // {
  //   path: "product-details/:id",
  //   component: SingleProductDetailsComponent
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShopRoutingModule {}
