const DefaultWebalias = "samspace";

function getBaseLocation() {
  const registerRoute = [
    "/home",
    "/login",
    "/signup",
    "/products/[a-zA-Z0-9]",
    "/product/[a-zA-Z0-9]",
    "/product-detail/[a-zA-Z0-9]",
    "/application",
    "/join",
    "/pages/[A-Za-z]",
    "/complete",
    "/main",
    "/about",
    "/checkout",
    "/faq",
    "/orderhistory",
    "/manageautoship",
    "/autoorderhistory",
    "/complete",
    "/404",
    "/order-success",
    "/signup",
    "/orderinvoice",
    "/forgotpassword",
    "shipping",
    "/become-a-distributor",
    "/enrollment",
    "/testimonials",   
    "/app",
    "/terms-of-service",
    "/scientific-research",
    "/enrollment/welcome",
    "/enrollment/email-capture",
    "/event-calendar",
    "/referral-code-entry",
    "/email-address",
    "/logindetails",
    "/forgot-password",
    "/signup-with-email",
    "/sign-up-information",
    "/bio",
    "/account",
    "/account/profile",
    "/account/orders",
    "/account/autoships",
    "/account/information",
    "/account/addresses",
    "/account/payment-methods"

  ];
  if (registerRoute.includes(location.pathname)) {
    return `/${DefaultWebalias}`;
  }
  if (
    location.pathname.includes("pages/") &&
    location.pathname.includes("checkout/") &&
    location.pathname.includes("enrollment/")
  ) {
    const routeMatch = registerRoute.some((item) => {
      return new RegExp(item).test(location.pathname);
    });
    if (routeMatch) {
      return `/${DefaultWebalias}`;
    }
  }
  const paths: string[] = location.pathname.split("/").splice(1, 1);
  const basePath: string = (paths && paths[0]) || `${DefaultWebalias}`;
  return "/" + basePath;
}

export { DefaultWebalias, getBaseLocation };
