<div class="product_page px-16 py-5 max-w-[1400px] mx-auto">
  <div class="flex flex-col sm:flex-row justify-between items-center">
    <div class="text-lg font-semibold text-gray-800 mb-4 sm:mb-0">
      <p class="text-secondary category-text">Home > <span class="text-primary">{{selectedCategory?.Name}}</span></p>
    </div>
  
    <div class="relative">
     <select (change)="onSortChange($event)" class="bg-white border border-gray-300 py-2 px-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary">
       <option value="" disabled selected hidden>Sort</option>
       <option value="sort-asc-name">Sort by Name Ascending</option>
       <option value="sort-desc-name">Sort by Name Descending</option>
       <option value="sort-asc-price">Sort by Price Ascending</option>
       <option value="sort-desc-price">Sort by Price Descending</option>
     </select>
    </div>
  </div>
  
  <div class="flex flex-col sm:flex-row p-4 px-0">
    <div class="w-full sm:w-1/5 p-2 pl-0">
      <div class="bg-white p-4 pl-0">
       <h2 class="mb-4 pb-2 text-secondary border-b border-dotted border-[#00A19B]" style="font-size: 22px; font-weight: 500;">Browse By:</h2>
         <ul class="space-y-2">
           <li *ngFor="let category of categories">
             <a (click)="onCategoryChange(category.Name)" [ngClass]="{'text-primary': category.ID == selectedCategoryId, 'text-secondary': category.ID != selectedCategoryId}" 
             class="font-medium text-lg cursor-pointer flex gap-1 items-center"><svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7"/>
            </svg> 
             {{ category.Name }} </a>
           </li>
         </ul>

         <div class="mt-8">
           <h3 class="mb-2 pb-2 text-secondary border-b border-dotted border-[#00A19B] " style="font-size: 22px; font-weight: 500;">Price Range:</h3>
           <div class="flex space-x-2">
             <input type="number" [(ngModel)]="minPrice" placeholder="Min" class="w-full text-lg p-2 border border-gray-300 rounded" />
             <input type="number" [(ngModel)]="maxPrice" placeholder="Max" class="w-full p-2 border border-gray-300 rounded" />
           </div>
           <div class="w-full flex flex-col items-end">
             <button (click)="applyPriceFilter()" class="mt-4 bg-primary text-white px-8 py-2">Filter</button>
           </div>
         </div>
         <div class="mt-8">
           <h3 class=" mb-2 pb-2 text-secondary border-b border-dotted border-[#00A19B]" style="font-size: 22px; font-weight: 500;">3rd Filter</h3>
           <div class="p-4 bg-gray-50 text-center">
             <p class="text-secondary text-lg font-semibold">Avini 3rd Filter</p>
           </div>
         </div>
      </div>
    </div>
  
    <div class="w-full sm:w-4/5 p-2">
      <div class="bg-white">
        <div *ngIf="productList.length === 0 && (isLoading==false)" class="p-4 text-center text-lg font-bold text-primary">
          No products found!
        </div>
        <div *ngIf="productList.length === 0 && isLoading" class="p-4 text-center text-lg font-bold text-primary">
          Loading Products...
        </div>
        <div *ngIf="productList.length > 0" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <div *ngFor="let product of productList" class="p-2 flex flex-col items-center">
            <img (click)="navigateToProductDetails(product.ItemID)" [src]="imageUrl + product.ImageUrl" alt="{{imageUrl + product.ProductName }}" class="w-full min-h-[265px] h-[265px] object-cover rounded-t-2xl cursor-pointer">
            
            <div class="w-full p-4 flex flex-col items-start text-start bg-[#fcfcfc] rounded-b-2xl product-details-section">
              
              <div class="product-name-price">
                <p class="text-base font-medium text-secondary">{{ product.ProductName }}</p>
                <p class="text-lg font-bold text-secondary my-2">${{ product.Price }}</p>
              </div>
              <button (click)="navigateToProductDetails(product.ItemID)" class="w-full bg-primary text-white py-2 px-4 rounded-lg border border-transparent shadow-md hover:border-gray-500 hover:shadow-lg transition">Order Now</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
